<template>
  <ScrollList :list="dataList" :height="height">
    <!-- 第三方滚动列表规定scrollArr命名为scroll_list -->
    <template v-slot:scrollArr="{ scroll_list }">
      <div class="template-box">
        <div
          class="template-item"
          :class="selectedIndex == index ? 'selected' : ''"
          v-for="(item, index) in scroll_list"
          :key="index"
          @click="onSelectTemplate(item, index)"
        >
          <template v-if="item.Code == 'FillPicGroup'">
            <FillPictrueGroup :dataList="item"></FillPictrueGroup>
          </template>

          <div class="delete-btn" @click.stop="onRemoveTemplate(index)">删除</div>
        </div>
      </div>
    </template>
  </ScrollList>
</template>

<script>
import ScrollList from "@/components/zh-scrollList/scrollList/scrollList";
import FillPictrueGroup from "@/views/setting/components/fill-pictrue-group/fill-pictrue-group.vue";

export default {
  components: {
    ScrollList,
    FillPictrueGroup,
  },

  props: {
    height: {
      type: String,
      default: () => "",
    },

    dataList: {
      type: Array,
      default: () => [],
    },

    selectedIndex: {
      type: Number,
      default: () => -2
    }
  },

  // watch: {
  //   dataList:{
  //     handler(newVal){
  //       // console.log(newVal);
  //     },
  //     // immediate: true,
  //     deep:true
  //   }
  // },

  data() {
    return {
      
    };
  },

  methods: {
    onSelectTemplate(item, index) {
      this.$emit("onSelected", { dataInfo: item, index });
    },

    onRemoveTemplate(index){
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
            this.dataList.splice(index, 1)
            this.$emit('onRemove');
        })
    },
  },
};
</script>

<style lang="less" scoped>
.template-box {
  .template-item {
    position: relative;
    border: 2px dashed rgba(0, 0, 0, 0.01);

    .delete-btn{
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 5px;
        color: white;
        background-color: rgba(0, 0, 0, .4);
    }
  }

  .template-item:hover,
  .selected {
    border: 2px dashed #20a0ff;
    cursor: pointer;

    .delete-btn{
        display: block;
    }
  }
}
</style>
