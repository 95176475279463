<template>
  <div class="design" :style="[{ '--maxHeight': maxHeight + 'px' }]">
    <!-- 组件库 -->
    <div class="left">
      <div class="component-box">
        <div class="top">
          <div class="bule"></div>
          <span class="title">组件库</span>
        </div>

        <div class="list-box">
          <div
            v-for="(classItem, classIndex) in componentList"
            :key="classIndex"
            class="list-item"
          >
            <span class="title">{{ classItem.className }}</span>

            <div class="component-list">
              <div
                v-for="(childItem, childIndex) in classItem.classChild"
                :key="childIndex"
                class="component-item"
                @click="onAddTemplate(childItem)"
              >
                <span class="df-icon" :class="childItem.icon"></span>
                <span class="name">{{ childItem.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <el-button type="primary" size="mini" @click="onSavePageData"
            >保存页面</el-button
          >
        </div>
      </div>
    </div>

    <!-- 预览视窗 -->
    <div class="main">
      <div class="preview-box">
        <div
          class="navbar-box"
          :class="templateIndex == -1 ? 'selected' : ''"
          :style="[
            {
              '--headColor': pageHeadInfo.TitleForeColor || 'white',
              '--headBackgroundColor': pageHeadInfo.TitleBackColor || 'black',
            },
          ]"
          @click="onSelectNavbar"
        >
          <div class="bangs-bar">
            <div class="task-bar">
              ●●●●●●
            </div>

            <span>下午 4:55</span>

            <div class="electricity">
              <span class="dfWebIcon dfWebIcon-jurassic_clock"></span>
              <span class="dfWebIcon dfWebIcon-dianliang"></span>
            </div>
          </div>

          <div class="title-bar">
            <span></span>
            <span class="title">{{ pageHeadInfo.PageTitle }}</span>
            <div class="more">
              ●●●
            </div>
          </div>
        </div>

        <TemplateScrollList
          height="587"
          :dataList="templateList"
          :selectedIndex="templateIndex"
          @onSelected="onSelectTemplate"
          @onRemove="onRemoveTemplate"
        ></TemplateScrollList>
      </div>
    </div>

    <!-- 组件参数设置 -->
    <div class="right">
      <TemplateSetting v-show="selectedTempInfo.Code || templateIndex == -1" :selectedTempInfo="selectedTempInfo" :templateIndex="templateIndex"></TemplateSetting>
    </div>
    
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

import TemplateScrollList from "@/views/setting/components/template-scroll-list.vue";
import TemplateSetting from "@/views/setting/components/template-setting.vue";

export default {
  components: {
    TemplateScrollList,
    TemplateSetting,
  },

  data() {
    return {
      pageId: 0,  // 页面Id
      maxHeight: "",  // 页面最大高度
      templateIndex: -2,  // 选中模板的下标
      componentList: [  // 组件库组件列表
        { // 分类
          id: "A",
          className: "媒体组件",
          classChild: [ // 组件
            {
              id: "A1",
              name: "单图组",
              code: "FillPicGroup",
              icon: "dfWebIcon dfWebIcon-gallery-line",
            },
          ],
        },
      ],
      templateList: [], // 模板数据列表
      pageHeadInfo: {}, // NavigateBar顶部导航栏数据信息
      selectedTempInfo: {}, // 选中模板的信息
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    // console.log(this.$route.query);
    this.pageId = Number(this.$route.query.PageId);
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.maxHeight = clientHeight - (100 + 40 + 20 + 48 + 56);
      // console.log(clientHeight);
    },

    // 初始化页面数据
    async initPageData() {
      try {
        let { data } = await Wxmpdiy.initPageData({ page_id: this.pageId });
        // data.PageHeadData = {
        //   ...data.PageHeadData,
        //   Name: "页面设置",
        //   Code: "HeadSetting",
        // };
        if (data.PageHeadData.PageId == 0) {
          data.PageHeadData = {
            PageId: 0,
            PageName: "页面名称",
            PageTitle: "页面标题",
            ShareTitle: "分享标题",
            TitleForeColor: "#000000",
            TitleBackColor: "#ffffff",
          };
        }
        this.pageHeadInfo = data.PageHeadData;
        this.templateList = data.PageBodyData.Widgets;
      } catch (error) {
        console.log(error);
      }
    },

    // 保存页面按钮点击事件
    async onSavePageData() {
      let submitData = {
        PageHeadData: this.pageHeadInfo,
        PageBodyData: {
          Widgets: this.templateList || [],
        },
      };
      try {
        let { data, errcode } = await Wxmpdiy.savePageData(submitData);
        if (errcode == 0) {
          this.$message.success("更新成功!");
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 添加/插入一个模板组件
    onAddTemplate(event) {
      let { templateList, templateIndex } = this;
      // let param = { Code: event.code, Name: event.name };
      // 临时写死模版数据以后要抽出来做组件
      let param = {
        Code: "FillPicGroup",
        Name: "单图组",
        MarginTopBottom: 0,
        MarginLeftRight: 0,
        BackColor: "rgba(255,255,255,1)",
        PicItems: [
          {
            PicUrl: "",
            LinkPath: "",
          },
          {
            PicUrl: "",
            LinkPath: "",
          },
        ],
      };

      if (templateList.length == templateIndex + 1 || templateIndex == -2) {
        this.templateList.push(param);
        // console.log(this.templateList);
        return;
      }
      this.templateList.splice(templateIndex + 1, 0, param);
      // console.log(this.templateList);
    },

    // 移除预览模板
    onRemoveTemplate() {
      if (!this.templateList.length) {
        this.templateIndex = -2;
      }
      this.selectedTempInfo = {};
    },

    // 选中标题栏
    onSelectNavbar() {
      this.onSelectTemplate({ dataInfo: this.pageHeadInfo, index: -1 });
    },

    // 选中模板
    onSelectTemplate(event) {
      this.selectedTempInfo = event.dataInfo;
      this.templateIndex = event.index;
      // console.log(this.selectedTempInfo);
    },
  },
};
</script>

<style lang="less" scoped>
.design {
  .flex-row();
  height: var(--maxHeight);

  > div {
    flex: 1;
    height: 100%;

    > div {
      .flex-col();
      align-items: unset;
      width: 60%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #ccc;
    }
  }

  .left,
  .right {
    > div {
      padding: 10px;
      > div {
        padding: 10px 0;
      }
    }
  }

  .left,
  .right {
    .component-box {
      .top {
        .flex-row();
        border-bottom: 1px solid #ddd;

        .bule {
          height: 70%;
          width: 4px;
          margin-right: 8px;
          background: #20a0ff;
        }
      }

      .list-box {
        flex: 1;
        max-height: calc(var(--maxHeight) - 129px);
        overflow-y: auto;

        .title {
          font-size: 12px;
          color: #999;
        }

        .component-list {
          .flex-row();
          flex-wrap: wrap;
          width: 100%;
          box-sizing: border-box;

          .component-item {
            .flex-col();
            justify-content: center;
            width: 75px;
            height: 55px;
            padding: 5px 0;
            margin: 10px;
            background-color: #f1f1f1;
            border: 1px solid #ddd;
            box-sizing: border-box;
            transition: all 0.3s ease-in-out;

            span {
              .flex-col();
              justify-content: center;
              flex: 1;
              font-size: 12px;
            }

            .df-icon {
              font-size: 18px;
              color: rgba(0, 0, 0, 0.6);
            }
          }

          .component-item:hover {
            background-color: white;
            border-color: #20a0ff;
            cursor: pointer;
          }
        }
      }

      .footer {
        text-align: right;
        border-top: 1px solid #ddd;
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.07);
      border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.15);
      transition: color 0.2s ease;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  // .right .list-box {
  //   // overflow-y: unset !important;
  //   max-height: unset !important;
  // }

  .main {
    .flex-row();
    justify-content: center;
    align-items: unset;
    box-sizing: border-box;

    .preview-box {
      width: 375px; // 根据手机的分辨率设置宽高
      height: 667px;
      border-color: rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .navbar-box {
        .flex-col();
        width: 100%;
        height: 80px;
        box-sizing: border-box;
        border: 2px dashed rgba(255, 255, 255, 0.01);
        background-color: var(--headBackgroundColor);

        > div {
          .flex-row();
          width: 100%;
          flex: 1;

          > div,
          > span {
            .flex-row();
            justify-content: center;
            flex: 1;
            font-size: 12px;
          }

          .task-bar,
          .more {
            font-size: 14px;
          }
        }

        .bangs-bar {
          .electricity {
            span:first-child {
              font-size: 10px;
              margin-right: 3px;
            }
            span:last-child {
              font-size: 20px;
            }
          }

          > span {
            flex: 2;
            font-weight: bold;
          }
        }

        .task-bar {
          letter-spacing: -2px;
          .title {
            font-size: 16px;
          }
        }

        .title-bar {
          .title {
            flex: 2;
            font-size: 14px;
            color: var(--headColor);
          }
        }
      }

      .navbar-box:hover,
      .selected {
        border-color: #20a0ff;
        cursor: pointer;
      }
    }
  }

  .right {
    .flex-row();
    justify-content: right;
  }

  .hidden {
    display: none;
  }
}
</style>
