<template>
  <div class="fill-pictrue-group" :style="[style]">
    <div
      class="pictrue-item"
      :style="item.PicUrl ? '' : 'height: 200px'"
      v-for="(item, index) in dataList.PicItems"
    >
      <!-- <el-image :src="item.PicUrl || defaultPicUrl" fil="none"></el-image> -->
      <img :src="item.PicUrl || require('@/assets/img/no-pictrue-rectangle.png')" :draggable="false" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: () => ({
        MarginTopBottom: 0,
        MarginLeftRight: 0,
      }),
    },
  },

  watch: {
    dataList: {
      handler(newVal) {
        // let object = {
        //   Code: "FillPicGroup",
        //   Name: "单图组",
        //   MarginTopBottom: 0,
        //   MarginLeftRight: 0,
        //   BackColor: "rgba(255,255,255,1)",
        //   PicItems: [
        //     {
        //       PicUrl: "",
        //       LinkPath: "",
        //     },
        //     {
        //       PicUrl: "",
        //       LinkPath: "",
        //     },
        //   ],
        // };
        // let param;
        // // console.log(newVal.PicItems);
        // if (!newVal.PicItems) {
        //   param = JSON.parse(JSON.stringify(object));
        // }
        this.style = {
          "--padding": `${newVal.MarginTopBottom}px ${newVal.MarginLeftRight}px`,
          "--backgroundColor": `${newVal.BackColor}`,
        };
        // this.list = param;
        // return param;
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      // defaultPicUrl: require("assets/img/no-pictrue-rectangle.png"),
      list: {},
      style: {
        "--padding": "",
        "--backgroundColor": "",
      },
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.fill-pictrue-group {
  width: 100%;
  height: auto;
  padding: var(--padding);
  box-sizing: border-box;
  background-color: var(--backgroundColor);

  .pictrue-item {
    padding: var(--padding);

    .el-image,
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
