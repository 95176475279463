<template>
  <div class="">
    <div class="box" :style="[{ '--maxHeight': height + 'px' }]">
      <div :style="[scroll_box]">
        <slot name="scrollArr" :scroll_list="scroll_list"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * @param {list,Array} 滚动数据
 * @param {time,Number} 滚动间隔时间
 */
export default {
  name: "scrollList",
  props: {
    list: {
      type: Array,
      default: [],
    },

    height: {
      type: String,
      default: "",
    },

    time: {
      typeof: Number,
      default: 2000,
    },
  },
  data() {
    return {
      scroll_list: this.list,
      timer: null, //定时器
      scroll_box: {}, //上拉加载样式
    };
  },
  mounted() {},
  methods: {
    scroll() {
      if (this.scroll_list.length <= 4) {
        //低于4条不滚动
        return;
      }
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        setTimeout(() => {
          this.scroll_list.push(this.scroll_list[0]);
          this.scroll_list.shift();
          this.scroll_box = {
            "margin-top": 0,
            transition: "",
          };
        }, 500);

        this.scroll_box = {
          "margin-top": "-60rpx",
          transition: "all 0.5s",
        };
      }, this.time);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    list: {
      handler(news, old) {
        this.scroll_list = news;
        this.scroll();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  // height: 520upx;
  height: var(--maxHeight);
  line-height: 60upx;
  overflow: hidden;

  > div {
    height: 100%;
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
  transition: color 0.2s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
</style>
